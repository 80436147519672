var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"choices-type-input"}},[_vm._v("Deposit Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit.deposit_type),expression:"deposit.deposit_type"}],staticClass:"form-control",attrs:{"name":"choices-type-input","id":"choices-type-input"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.deposit, "deposit_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"REVENUE","selected":""}},[_vm._v("Revenue")])])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Total Amount")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit.amount),expression:"deposit.amount"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.$v.deposit.amount.$error,
                                },attrs:{"id":"validationCustom01","type":"text","placeholder":"$","value":"Mark"},domProps:{"value":(_vm.deposit.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deposit, "amount", $event.target.value)}}}),(_vm.$v.deposit.amount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.deposit.amount.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom03"}},[_vm._v("Deposit Date")]),_c('flat-pickr',{staticClass:"form-control",class:{
                                    'is-invalid': _vm.$v.deposit.post_date.$error,
                                },attrs:{"placeholder":"Select a date"},model:{value:(_vm.deposit.post_date),callback:function ($$v) {_vm.$set(_vm.deposit, "post_date", $$v)},expression:"deposit.post_date"}}),(_vm.$v.deposit.post_date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.deposit.post_date.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Cheque Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit.cheque_number),expression:"deposit.cheque_number"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.$v.deposit.cheque_number.$error,
                                },attrs:{"id":"validationCustom01","type":"text","placeholder":"","value":""},domProps:{"value":(_vm.deposit.cheque_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deposit, "cheque_number", $event.target.value)}}}),(_vm.$v.deposit.cheque_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.deposit.cheque_number.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"choices-bank-account"}},[_vm._v("Bank Account")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit.bank_account_id),expression:"deposit.bank_account_id"}],staticClass:"form-control",attrs:{"name":"choices-bank-account","id":"choices-bank-account"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.deposit, "bank_account_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"choices-bank-account"}},[_vm._v("Payor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit.payor),expression:"deposit.payor"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.$v.deposit.payor.$error,
                                },attrs:{"id":"validationCustom01","type":"text","placeholder":"","value":""},domProps:{"value":(_vm.deposit.payor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deposit, "payor", $event.target.value)}}}),(_vm.$v.deposit.payor.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.deposit.payor.required)?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"validationCustom01"}},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit.description),expression:"deposit.description"}],staticClass:"form-control",attrs:{"id":"validationCustom01","type":"text","placeholder":"","value":""},domProps:{"value":(_vm.deposit.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deposit, "description", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 mb-3"},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","use-custom-slot":true,"options":_vm.dropzoneOptions},on:{"vdropzone-success":_vm.fileUploaded}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('i',{staticClass:"display-4 text-muted bx bxs-cloud-upload"}),_c('h4',[_vm._v("Drop scaned cheque and receipt to here.")])])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('div',{staticClass:"d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center"},[_c('b-button',{attrs:{"variant":"primary","size":"md","type":"submit"}},[_vm._v("Submit")])],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }