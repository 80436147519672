

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Choices from "choices.js";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";


import {
    required,
} from "vuelidate/lib/validators";



import { getArApApi, getCashApi } from '@/api/cash'




export default {
    page: {
        title: "Create AP Expense ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {

        deposit: {
            amount        : { required },
            post_date     : { required },
            cheque_number : {required},
            payor         : {required},
        },
    },


    data() {
        return {
            title: "Deposit Cheque",
            items: [
                {
                    text: "AR",
                    href: "/ar/deposit_cheque",
                },
                {
                    text: "Deposit Cheque",
                },
            ],

            deposit : {
                deposit_type    : 'REVENUE',
                amount          : 0,
                post_date       : '',
                description     : '',
                bank_account_id : '',
                cheque_number   : '',
                payor           : '',
            },
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
            },
            type_choice         : {},
            bank_account_choice : {},
            bank_account_list   : [],
            file_validate_failed : true
        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
        vueDropzone: vue2Dropzone,
    },

    methods: {

        formSubmit() {

            if (this.file_validate_failed) {
                this.$alertify.error("Please Upload scanned Cheque  ");
                return;
            }
           
            this.$v.$touch();
           
            if (this.$v.$invalid == false) {
                let data = {
                    deposit : this.deposit
                }
                getArApApi().deposit_cheque(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Cheque is deposited" );
                        this.doReset()
                    } else {
                        this.$alertify.error("Failed to Deposit " + res.errCode);
                    }
                })
            }
        },

        doReset() {
          
        },

        fileUploaded(ev, resp) {
            console.log("uploaded" , ev.upload, ev, resp)
            if (resp[0].errCode == 200) {
                this.file_validate_failed = false
                this.file = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name :resp[0].response.filename,
                    size : resp[0].response.size,
                    type : resp[0].response.type
                }
            } else {
                this.file_validate_failed = true
            }
        }




    },




    created() {

       

        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                    this.bank_account_list.push({
                        label : b.name,
                        value : b.id,
                        bank_account : b
                    })
                })

                this.bank_account_choice.setChoices(this.bank_account_list)
            }
        })
        


    },

    mounted() {
       

        this.type_choice = new Choices('#choices-type-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        this.bank_account_choice = new Choices('#choices-bank-account', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

    }
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form class="needs-validation" @submit.prevent="formSubmit">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label for="choices-type-input" class="form-label">Deposit Type</label>
                                    <select class="form-control" v-model="deposit.deposit_type" name="choices-type-input" id="choices-type-input">
                                        <option value="REVENUE" selected>Revenue</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Total Amount</label>
                                <input id="validationCustom01" v-model="deposit.amount" type="text" class="form-control"
                                    placeholder="$" value="Mark" :class="{
                                        'is-invalid': $v.deposit.amount.$error,
                                    }" />
                                <div v-if="$v.deposit.amount.$error" class="invalid-feedback">
                                    <span v-if="!$v.deposit.amount.required">This value is required.</span>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="validationCustom03">Deposit Date</label>
                                <flat-pickr v-model="deposit.post_date" placeholder="Select a date" class="form-control"
                                    :class="{
                                        'is-invalid': $v.deposit.post_date.$error,
                                    }"></flat-pickr>
                                <div v-if="$v.deposit.post_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.deposit.post_date.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Cheque Number</label>
                                <input id="validationCustom01" v-model="deposit.cheque_number" type="text" class="form-control"
                                    placeholder="" value="" :class="{
                                        'is-invalid': $v.deposit.cheque_number.$error,
                                    }" />
                                <div v-if="$v.deposit.cheque_number.$error" class="invalid-feedback">
                                    <span v-if="!$v.deposit.cheque_number.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row-->


                    <div class="row">
                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="choices-bank-account" class="form-label">Bank Account</label>
                                <select class="form-control" v-model="deposit.bank_account_id" name="choices-bank-account" id="choices-bank-account">
                                </select>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="choices-bank-account" class="form-label">Payor</label>
                                <input id="validationCustom01" v-model="deposit.payor" type="text" class="form-control"
                                    placeholder="" value="" :class="{
                                        'is-invalid': $v.deposit.payor.$error,
                                    }" />
                                <div v-if="$v.deposit.payor.$error" class="invalid-feedback">
                                    <span v-if="!$v.deposit.payor.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-9">
                            <div class="mb-3">
                                <label for="validationCustom01">Description</label>
                                <input id="validationCustom01" v-model="deposit.description" type="text"
                                    class="form-control" placeholder="" value="" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="fileUploaded">
                                <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop scaned cheque and receipt to here.</h4>
                                </div>
                            </vue-dropzone>
                        </div>

                    </div>
                    <!-- end row-->


                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="primary" size="md" type="submit">Submit</b-button>
                            </div>
                        </div>
                        
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>